<template>
    <div>
        <!-- EDIT MODAL -->
        <b-modal no-close-on-backdrop ok-title="Submit" :title="'Edit ' + currentConfig.constellationname + ' Config'"
                 class="modal-dark" v-model="editModal">
            <label class="form-check-label">Retention</label>
            <input @keyup.enter="editEnterButton()"
                   :class="{ 'border-danger': !currentConfig.retention || Number(currentConfig.retention) < Number(currentConfig.retention_min)}"
                   class="form-control" placeholder="2" v-model="currentConfig.retention">
            <br>
            <label class="form-check-label">Retention Min</label>
            <input @keyup.enter="editEnterButton()" placeholder="1"
                   :class="{ 'border-danger': !currentConfig.retention_min || Number(currentConfig.retention) < Number(currentConfig.retention_min)}"
                   class="form-control" v-model="currentConfig.retention_min">
            <br>
            <label class="form-check-label">Disk Percentage Max</label>
            <input @keyup.enter="editEnterButton()" placeholder="80"
                   :class="{ 'border-danger': !currentConfig.disk_percentage_max}" class="form-control"
                   v-model="currentConfig.disk_percentage_max">
            <br>
            <label class="form-check-label">Hold</label>
            <b-form-select v-model="currentConfig.hold">
                <option :value="true">true</option>
                <option :value="false">false</option>
            </b-form-select>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="editModal=false">Cancel</b-button>
                <b-button
                    :disabled="!currentConfig.retention || !currentConfig.retention_min || !currentConfig.disk_percentage_max || Number(currentConfig.retention) < Number(currentConfig.retention_min)"
                    size="sm" variant="primary" @click="editSubmit">Submit
                </b-button>
            </template>
        </b-modal>

        <!-- PAGE BODY -->
        <b-card style="width: 100%; height: 100%;" no-body>
            <div slot="header" no-body>
                <h5><b>Storage Config</b></h5>
            </div>
            <b-card-body>
                <v-client-table ref="dTable" @row-click="rowClicked" style="width: 100%" :columns="columns"
                                :data="configs" :options="options" :theme="theme" class="dataTable">
                    <a slot="edit" slot-scope="props" target="_blank" :href="props.row.info"
                       style="float: right; cursor:pointer" @click="showEditModal(props.row)"
                       class="fa fa-edit fa-lg"></a>
                    <div slot="hold" slot-scope="props">
                        {{ props.row.hold }}
                    </div>
                </v-client-table>
                <b-tooltip v-if="tooltipRender" ref="tooltip" :target="target" :title="tooltipTitle"
                           triggers=""></b-tooltip>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import constellation from '../../../services/constellation.js'
import {ClientTable} from 'vue-tables-2'
import Vue from 'vue'
import constellationStore from "@/store/constellationStore";
import PubSub from "pubsub-js";

Vue.use(ClientTable);

export default {
    name: "Config",
    data: () => {
        return {
            //Data Table
            pubsub: null,
            tooltipTitle: "",
            tooltipRender: true,
            target: '',
            columns: ['constellationname', 'retention', 'retention_min', 'disk_percentage', 'disk_percentage_max', 'hold', 'edit'],
            options: {
                orderBy: {column: 'constellationname', ascending: true},
                headings: {
                    constellationname: 'Constellation',
                    retention: 'Retention',
                    retention_min: 'Retention Min',
                    disk_percentage: 'Disk Percentage',
                    disk_percentage_max: 'Disk Percentage Max',
                },
                sortable: ['constellationname', 'retention', 'retention_min', 'disk_percentage', 'disk_percentage_max', 'hold'],
                filterable: ['constellationname', 'retention', 'retention_min', 'disk_percentage', 'disk_percentage_max', 'hold'],
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 25,
                skin: 'table table-striped table-hover',
            },
            theme: 'bootstrap4',
            configs: [],
            editModal: false,
            currentConfig: {},
        }
    },
    created() {
        this.getConstellations();
    },
    beforeDestroy() {
        PubSub.unsubscribe(this.pubsub);
    },
    methods: {
        getConstellations() {
            constellationStore.dispatch('getConstellations').then(constellations => {
                this.getConfig(constellations)
                this.pubsub = PubSub.subscribe('updateconstellations', (msg, data) => {  // refreshes table (unuseable)
                    this.updateConstellations(data);
                });
            })
        },
        getConfig(constellations) {
            constellations.forEach(constell => {
                constellation.readConstellationConfig(constell.url + ':' + constell.port).then(res => {
                    let curRetention = this.configs.find(_ => _._id === res.data._id);
                    res.data.constellationId = constell._id
                    res.data.constellationname = constell.constellationname;
                    res.data.url = constell.url;
                    res.data.port = constell.port;
                    if (curRetention) {
                        Object.assign(curRetention, res.data)
                    } else {
                        this.configs.push(res.data)
                    }
                }).catch(() => {
                    let index = this.configs.findIndex(_ => _.constellationId === constell._id);
                    if (index) {
                        this.configs.splice(index, 1);
                    }
                })
            })
        },
        updateConstellations(constellations) {
            constellations.forEach(constellation => {
                let cur = this.configs.find(_ => _.constellationId === constellation._id)
                if (cur) {
                    Object.assign(cur, {
                        constellationname: constellation.constellationname,
                        url: constellation.url,
                        port: constellation.port
                    })
                }
            })
        },
        rowClicked(item) {
            //ctrl click to make tooltip appear with id
            if (item.event.ctrlKey) {
                this.tooltipRender = false;
                this.tooltipTitle = item.row._id;
                item.event.target.id = item.row._id;
                this.target = item.row._id;
                //restart tooltip so it can map to its new position
                Vue.nextTick().then(() => {
                    this.tooltipRender = true;
                    Vue.nextTick().then(() => {
                        this.$refs.tooltip.$emit('open')
                        setTimeout(function () {
                            //make the tooltip disapear automatically
                            this.$refs.tooltip.$emit('close');
                        }.bind(this), 4000)
                    });
                });
            }
        },
        showEditModal(config) {
            this.currentConfig = JSON.parse(JSON.stringify(config));
            this.editModal = true;
        },
        editEnterButton() {
            if (Boolean(this.currentConfig.retention) && Boolean(this.currentConfig.retention_min) && Boolean(this.currentConfig.disk_percentage_max)) {
                this.editSubmit();
            }
        },
        editSubmit() {
            this.editModal = false;
            constellation.updateConstellationConfig(this.currentConfig.url + ':' + this.currentConfig.port, this.currentConfig).then(() => {
                this.getConstellations();
                this.$mToast({
                    title: 'Success',
                    text: "Config updated",
                    style: 'success'
                });
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Site couldn't be stopped: " + error.response.statusText,
                    style: 'error'
                });
            })
        },
        state(array) {
            if (array.length > 0) {
                return null;
            }
            return false;
        },
    }
}
</script>
