import Vue from 'vue';
import Vuex from 'vuex';
import PubSub from 'pubsub-js';
import iss from '@/services/iss.js'

import authStore from "@/store/authStore";

Vue.use(Vuex);

let constellationStore = new Vuex.Store({

    state: {
        constellations: []
    },

    mutations: {
        updateConstellations(state, constellations) {
            state.constellations = JSON.parse(JSON.stringify(constellations));
            PubSub.publish('updateconstellations', constellations);
            constellations.forEach(constellation => {
                PubSub.publish('constellationupdate-' + constellation._id, constellation);
            })
        },
        addConstellation(state, constellation) {
            state.constellations.push(Object.assign({}, constellation));
            PubSub.publish("updateconstellations", state.constellations);
            PubSub.publish('constellationupdate-' + constellation._id, constellation);
        },
        postConstellation(state, constellation) {
            state.constellations.push(constellation);
            PubSub.publish('updateconstellations', state.constellations);
            PubSub.publish('constellationupdate-' + constellation._id, constellation);
        },
        putConstellation(state, constellation, index) {
            state.constellations.splice(index, 1, constellation);
            PubSub.publish('updateconstellations', state.constellations);
            PubSub.publish('constellationupdate-' + constellation._id, constellation);
        },
        deleteConstellation(state, toDelete) {
            state.constellations = state.constellations.filter(_ => _._id !== toDelete);
            PubSub.publish("updateconstellations", state.constellations);
        }
    },

    actions: {
        async getConstellations({commit, state}) {
            if (state.constellations.length === 0) {
                //let constellations = await iss.getConstellations();
                let constellations = await authStore.dispatch('getSegmentGrants');
                commit('updateConstellations', constellations);
            }
            return state.constellations;
        },
        async getConstellation({commit, state}, toFind) {
            let constellation = state.constellations.find(site => site._id === toFind);
            if (constellation === undefined) {
                let constellation = await iss.getConstellation(toFind);
                commit('addConstellation', constellation.data);
            }
            return state.constellations.find(_ => _._id === toFind);
        },
        async postConstellation({commit, state}, data) {
            let constellation = await iss.createConstellation(data);
            commit('postConstellation', constellation);
            return state.constellations.find(_ => _._id === constellation._id);
        },
        async putConstellation({commit, state}, data) {
            let constellation = await iss.editConstellation(data);
            if (constellation === undefined) {
                let index = state.constellations.findIndex(_ => _._id === data._id);
                commit("putConstellation", constellation, index)
            }
            return state.constellations.find(_ => _._id === constellation._id);
        },
        async deleteConstellation({commit, state}, toDelete) {
            await iss.deleteConstellation(toDelete);
            commit('deleteConstellation', toDelete)
        }
    },

    getters: {
        getConstellations(state) {
            return state.constellations;
        },
    }
});

export default constellationStore;